import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import ProfileLock from "./pages/ProfileLock";
import Error from "./pages/Error";

function App() {
  return (
    <Routes>
      <Route path="/:name" element={<Home />} />
      <Route path="/lock" element={<ProfileLock />} />
      <Route path="/error" element={<Error />} />
    </Routes>
  );
}

export default App;


