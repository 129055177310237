import React from 'react'
import errorImg from "../assets/error.jpg";
const Error = () => {
  return (
    <div className="h-[90vh] overflow-hidden w-full flex flex-col justify-center items-center">
        <img src={errorImg} className='w-[12rem] h-[12rem]' alt="" />
        <h3 className="text-2xl font-semibold abel">
          something Went wrong
        </h3>
    </div>
  );
}

export default Error