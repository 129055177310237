import React, { useReducer } from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import nameImg from "../assets/name.png";
import emailImg from "../assets/email.png";
import phoneImg from "../assets/phone.png";
import messageImg from "../assets/message.png";
import companyImg from "../assets/company.png";
import CircularLoading from "./CircularLoading";
import plusIcon from "../assets/plus-icon.png";
import jobImg from "../assets/job-title.png";
import LeadFormField from "./LeadFormField";

interface FormField {
  id: number;
  profile_id: number;
  title: string;
  is_required: boolean;
  value: string;
  error: boolean;
}

type FormAction =
  | { type: "CHANGE"; id: number; value: string }
  | { type: "VALIDATE" };

const formReducer = (state: FormField[], action: FormAction): FormField[] => {
  switch (action.type) {
    case "CHANGE":
      return state.map((field) =>
        field.id === action.id
          ? { ...field, value: action.value, error: false }
          : field
      );
    case "VALIDATE":
      return state.map((field) => ({
        ...field,
        error:
          (field.is_required && field.value.trim() === "") ||
          (field.title.toLocaleLowerCase() === "email" &&
            /\S+@\S+\.\S+/.test(field.value) === false) ||
          (field.title.toLocaleLowerCase() === "phone number" &&
            (/^[0-9]+$/.test(field.value) === false ||
              field.value.trim().length < 10)) ||
          (field.title.toLocaleLowerCase() === "full name" &&
            /^[a-z ,.'-]+$/i.test(field.value) === false),
      }));
    default:
      return state;
  }
};

const ModalContent: React.FC<{
  onClose: () => void;
  profile_id: number | undefined;
  profileImg: string;
  username: string;
  user_id: number | null;
  disclaimer: string;
  fields: FormField[];
  location: { latitude: number; longitude: number } | null;
}> = ({
  onClose,
  profile_id,
  profileImg,
  username,
  user_id,
  location,
  disclaimer,
  fields,
}) => {
  const [state, dispatch] = useReducer(formReducer, fields);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  console.log({
    state,
  });
  const submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch({ type: "VALIDATE" });
    const hasErrors = state.some(
      (field) =>
        (field.is_required && field.value.trim() === "") ||
        (field.title.toLocaleLowerCase() === "email" &&
          /\S+@\S+\.\S+/.test(field.value) === false) ||
        (field.title.toLocaleLowerCase() === "phone number" &&
          (/^[0-9]+$/.test(field.value) === false ||
            field.value.trim().length < 10)) ||
        (field.title.toLocaleLowerCase() === "full name" &&
          /^[a-z ,.'-]+$/i.test(field.value) === false)
    );
    console.log({
      hasErrors,
    });
    if (hasErrors) {
      setError(true);
      return setTimeout(() => {
        setError(false);
      }, 2000);
    }

    setLoading(true);
    try {
      const data = state.map((field) => {
        return { key: field.title, message: field.value };
      });

      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_SERVER}/api/profile/lead/connect`,
        {
          profile_id,
          lead_fields: data,
        }
      );
      // const res = await fetch(
      //   "https://lib.dghri.com/sendNotification.php",
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify(data),
      //   }
      // );

      setLoading(false);
      setSuccess(true);
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (err: Error | any) {
      setError(true);
    }
  };

  const handleChange = (id: number, value: string) => {
    dispatch({ type: "CHANGE", id, value });
  };

  return (
    <>
      <form
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
        className="relative flex flex-col justify-center items-center px-1 font-trial"
      >
        {/* profile pic */}
        <button
          className="absolute top-[0.2rem] right-[0.2rem]"
          onClick={onClose}
          data-dismiss="modal"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="20"
            height="20"
            viewBox="0 0 50 50"
          >
            <path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"></path>
          </svg>
        </button>
        <div className="  absolute top-[-4.8rem] px-2 drop-shadow-xl z-40  ">
          <img
            className="rounded-full h-[7rem] w-[7rem] object-cover "
            src={profileImg}
            alt=""
          />
        </div>
        <div className="mt-11 border-b py-4 w-full">
          <h3 className=" text-center text-xl px-10 ">
            Share your contact with {username}
          </h3>
        </div>
        {/* form inputs */}
        <div
          className={`
           overflow-y-scroll max-h-[14.5rem] flex flex-col w-full mt-2  gap-2 pt-2
           `}
        >
          <>
            {state.map((formField) => (
              <LeadFormField
                key={formField.id}
                id={formField.id}
                profile_id={formField.profile_id}
                required={formField.is_required}
                title={formField.title}
                value={formField.value}
                error={formField.error}
                handleChange={handleChange}
              />
            ))}
          </>
        </div>
        <button
          onClick={submitHandler}
          disabled={loading}
          className={`${success && "bg-green-500"} ${error && "bg-red-500"} ${
            !success && !error && "bg-[#222]"
          } rounded-full h-[3.5rem] w-[13rem] my-2  outline-none my- text-white flex justify-center items-center font-semibold drop-shadow-xl`}
        >
          {!loading && !success && "Connect"}
          {!loading && success && "Connected"}

          {loading && (
            <CircularLoading button={true} noPanner={true} panner="" />
          )}
        </button>
        <h3 className="text-xs text-black/60">{disclaimer}</h3>
      </form>
    </>
  );
};

export default ModalContent;
